.footer {
    flex-shrink: 0;
    padding-bottom: 15px;
}

.footerText {
    font-size: 8.5pt;
    color: #777;
    display: flex;
    margin-bottom: 10px;
}

.footerText a {
    color: #777;
}

.onlineIconContainer{
    position: relative;
    cursor: help;
    top: -10px;
}

.onlineIconContainer::after{
    content: attr(data-tooltip);
    position: absolute;
    right: 25px;
    top: 12px;
    transition: all ease 0.5s;
    width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-align: right;
    color: #FFF;
    background: linear-gradient(to right, rgba(39, 43, 48, 0), rgba(39, 43, 48, 1) 20%);
}

.onlineIconContainer:hover::after{
    opacity: 1;
    width: 220px;
}

.onlineIcon {
    height: 20px;
    margin-bottom: -20px;
}

.onlineTooltip {
    visibility: hidden;

}

.onlineIcon:hover .onlineTooltip {
    visibility: visible;
}

#paypalButton {
    text-align: center;
    font-size: 10pt;
}

.donateImage {
    opacity: 0.5;
    width: 50px;
    height: 14.1875px;
    margin-top: 5px;
    border-radius: 8px;
    background-color: rgb(255, 196, 58);
}
