.alarmContainer {
    display: flex;
    justify-content: flex-end;
}

.alarmToggle {
    width: 40px;
    padding: 8px;
    margin-right: 4px;
}

.alarmToggle .alarmIcon {
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 0.4;
}

.alarmToggle[aria-checked="true"] > .alarmIcon {
    opacity: 1;
}

.alarmControls {
    visibility: visible;
    display: flex;
    justify-content: center;
    margin-right: 4px;
    -webkit-user-select: none;
    transition: all 0.5s;
    height: 35px;
    max-width: 100px;
    opacity: 1;
}

.alarmAdjustButton {
    width: 40px;
    padding: 0 6px;
}

.minutesBeforeInput {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #777;
    font-size: 14pt;
    width: 65px;
}

.alarmMinutesLabel {
    color: #777;
    font-size: 7pt;
}

.alarmControls .mainButton {
    font-size: 15pt;
    touch-action: manipulation;
}

.alarmControlsInvisible {
    visibility: hidden;
    max-width: 0px;
    margin: 0;
    transform: scale(0, 1);
    opacity: 0;
}