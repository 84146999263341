.timeUntilHeading {
    font-size: 16pt;
    font-weight: lighter;
}

.countdown {
    font-size: 52pt;
    margin-top: 0;
}

.endOfDay {
    margin: 50px;
}