html, body {
  height: 100%;
  background-color: #272b30;
  color: #fff;
}

h3 {
  color: #7a8288;
  font-size: 20pt;
  text-shadow: -1px -1px 0 rgba(0,0,0,0.3);
}

a {
  color: #fff;
}

small {
  color: #777;
}

select {
  font-size: 16px;
  color: #777;
  line-height: 1.3;
  padding: .6em 1.4em .2em .2em;
  margin-right: 5pt;
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  appearance: none;
  outline-color: #559;
  outline-width: 1px;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23777%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  background-position: right 5px top 60%, 0 0;
  background-size: .65em auto, 100%;
  background-color: #272b30;
  cursor: pointer;
}

select:hover {
  color: #999;
}

li {
  padding-bottom: 10px;
}

.App {
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 800px;
  height: 100%;
}

.flexContainer {
  display: flex;
}

.flexColumn {
  flex-direction: column;
}

.spacer, .flexGrow {
  flex-grow: 2;
  min-width: 5px;
}

.width100 {
  width: 100%;
}

.height100 {
  height: 100%;
}

.noUnderline {
  text-decoration: none;
}

.paddingBottom {
  padding-bottom: 20pt;
}

.indent {
  margin-left: 20px;
}

.inline-block {
  display: inline-block;
}

.hidden {
  display: none;
}

.mobileOnly {
  display: none;
}

@media (max-width: 500px) {
  .noMobile {
    display: none;
  }
  .mobileOnly {
    display: block;
  }
}