.selectSchool {
    text-align: center;
    width: 100%;
    max-width: 500px;
    margin: auto;
}

.selectSchoolContainer {
    height: 100%;
    border: solid 2px #555;
    overflow: scroll;
}

.selectSchoolContainer input[type="radio"] {
    opacity: 0;
}

.selectSchoolContainer label {
    display: inline-block;
    width: 100%;
    padding: 20px 0;
    margin-top: -100%;
    font-size: 16px;
    text-align: left;
    border-bottom: solid 1px #000;
    cursor: pointer;
}

.selectSchoolContainer label div {
    padding-left: 20px;
}

.selectSchoolContainer label:hover, .selectSchoolContainer input:focus + label {
    background-color: #333;
}
  
.selectSchoolContainer input[type="radio"]:checked+label {
    background-color: rgb(20, 122, 255);
}

.schoolNotListed {
    margin-top: 20px;
}

.selectSchoolButton {
    height: 40px;
    width: 100px;
    border: none;
    background-color: rgb(20, 122, 255);
    color: #FFF;
    font-size: 12pt;
    cursor: pointer;
    text-transform: uppercase;
    display: block;
    margin: 15px 0 15px 0;
}

.selectSchoolButton:hover {
    background-color: rgb(53, 139, 250);
}

.selectSchoolButton:disabled {
    background-color: #444;
    cursor: default;
}

.privacyDisclosure {
    max-width: 450px;
    display: block;
    margin: 0 0 0 auto;
    text-align: right;
    font-size: 8pt;
}

.privacyDisclosure a {
    color: #777;
}