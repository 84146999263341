.header, .content {
    flex: 1 0 auto;
}

.scheduleSelect {
    max-width: 310px;
    width: 100%;
}

.mainButton {
    appearance: none;
    height: 35px;
    background-color: #333;
    color: #777;
    text-decoration: none;
    text-align: center;
    font-size: 8pt;
    border: 1px solid #444;
    outline-color: #559;
    outline-width: 1px;
    max-width: 70px;
    cursor: pointer;
    -webkit-user-select: none;
}

.cleanViewButton, .scheduleViewButton {
    padding: 8px;
}

.cleanViewIcon, .listIcon {
    height: 100%;
    object-fit: contain;
    opacity: 0.4;
}

.exitCleanViewButton {
    position: fixed;
    top: 10px;
    right: 10px;
    text-align: right;
}

.content {
    text-align: center;
}