.scheduleTableContainer {
    width: 50%;
    margin-bottom: 40px;
}

.scheduleTable {
    width: 95%;
    border-spacing: 0;
    margin-top: 10px;
}

@media (max-width: 705px) {
    .scheduleTableContainer {
        width: 100%;
    }

    .scheduleTable {
        width: 100%;
    }
}

.scheduleTableName {
    font-size: 14pt;
}

.scheduleTable th {
    border-bottom: 1px solid #dddddd;
}

.scheduleTable td {
    padding: 8px 0;
}

.scheduleTable td:first-child {
    padding-left: 5px;
}

.scheduleTable tr:nth-child(even) {
    background-color: #3b4047;
}

.scheduleTablePeriodNameHeader {
    text-align: left;
}

.scheduleTablePeriodName {
    width:50%
}

.scheduleTablePeriodStart, .scheduleTablePeriodEnd {
    text-align: center;
}

.scheduleTablePeriodDash {
    text-align: center;
}

.scheduleTableAMPM {
    font-size: 8pt;
    padding-left: 2px;
}