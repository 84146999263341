.siteTitle {
    color: #ddd;
    margin-top: 0;
    padding-top: 1em;
    margin-bottom: 5px;
}

.schoolInfo {
    appearance: none;
    background: none;
    border: none;
    font-size: 11pt;
    text-align: right;
    padding-top: 2em;
    padding-left: 5pt;
    text-decoration: none;
    cursor: pointer;
}

.schoolName {
    color: #aaa;
}

.changeSchool {
    display: block;
    text-decoration: underline;
}

.navigation a {
    color: #777;
    margin-right: 5px;
    padding: 1px 5px 1px 5px;
    background-color: #222;
    text-decoration: none;
    font-size: 10pt;
}

.navigation a:hover {
    color: #999;
    text-decoration: underline;
}